<template>
  <vx-card>
      <div>
        <h3>
          لیست کاربران  مهم
        </h3>
      </div>
    <br />
    <br />
    <vs-button @click="createNewimportatantUserPopup = true"> افزودن کاربر جدید </vs-button>
    <vs-popup class="holamundo"  title=" افزودن کاربران به لیست کاربران مهم" :active.sync="createNewimportatantUserPopup">
      <div class="centerx flex items-center default-input">
        <div class="centerx default-input">
          <vs-select
            class="selectExample"
            label="انتخاب کاربر"
            autocomplete
            @input-change="autoCompleteFunc"
            v-model="selectedUser">
            <vs-select-item v-for="(item,index) in usersAvailable"  :key="index" :value="item.id" :text="item.name"  />
          </vs-select>
        </div>
        <div class="centerx mt-5 ml-5 default-input">
          <vs-input disabled class="inputx" placeholder="Placeholder" v-model="selectedUser"/>
        </div>
      </div>
      <div class="flex justify-end">
        <vs-button class="mt-5" @click="handleSubmit">افزودن</vs-button>
      </div>
    </vs-popup>
    <br />
    <br />
    <div class="mt-5">
      <vs-table
        v-model="ImportantUser"
        :data="ImportantUser"
      >
        <template slot="thead">
          <vs-th>  شناسه </vs-th>
          <vs-th>  شناسه </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td>
              <router-link :to="'user?id='+data[indextr].users.id">
                {{ data[indextr].users.name }}
             </router-link>
            </vs-td>
            <vs-td>
              <vs-button @click="handleDelete(data[indextr].id)" color="danger" radius icon="delete" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
export default {
  name: 'UsersSalecooperation',
  data () {
    return {
      createNewimportatantUserPopup: false,
      usersAvailable: [],
      selectedUser:2,
      ImportantUser: [],
      options1:[
        {text:'IT',value:0},
        {text:'Blade Runner',value:2},
        {text:'Thor Ragnarok',value:3},
      ],
    }
  },
  methods: {
    autoCompleteFunc (e) {
      console.log('e.target.value',e.target.value)
      this.getUsersSalesFinder(e.target.value)
    },
    handleDelete (id) {
      this.$http.delete(`admin/importantusers/destroy/${id}`).then((res) => {
        console.log('res', res)
        this.getImportantUserlist()
        this.$vs.notify({ text: res.data.message, color: "success" });
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSubmit () {
      console.log('v-molde', this.selectedUser)
      this.$http.post('admin/importantusers/store', {
        user_id: this.selectedUser,
        important_reason: this.selectedUser
      }).then(res => {
        console.log('res', res)
        this.$vs.notify({ text: res.data.message, color: "success" });
      }).catch(err => {
        console.log('err', err)
        this.$vs.notify({ text: err.response.data.message, color: "danger" })
      })
    },
    getUsersSales () {
      this.$http.get('admin/user/list/').then((res) => {
        console.log('params',res.data.params.data)
        this.usersAvailable = res.data.params.data
      }).catch((err) => console.log(err))
    },
    getUsersSalesFinder (username) {
      this.$http.get(`admin/user/list?search=${username}`).then((res) => {
        console.log('paramssea',res.data.params.data)
        this.usersAvailable = res.data.params.data
      }).catch((err) => console.log(err))
    },
    getImportantUserlist () {
      this.$http.get('admin/importantusers/').then((res) => {
        console.log('res.data.params',res.data.params)
        this.ImportantUser = res.data.params
      }).catch((err) => {
        console.log('err', err)
      })
    }
  },
  mounted() {
    this.getUsersSales()
    this.getImportantUserlist()
  }
}
</script>

<style scoped>

</style>
